<template>
  <div class="system-setting">
    <div class="card">
        <div class="card-body">
          <h6>{{$trans('General Settings')}}</h6>
          <hr>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "System",
  data: function () {
    return {
      settings: {},
      process: false,
      alert: null,
      errors: {},
      deposit_methods: {},
    }
  },
  methods: {
    get_settings: function () {
      axios({url: this.$root.$data.api_url + '/api/settings/system', params: {}, method: 'GET'})
          .then(resp => {
            this.settings = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    save_settings: function () {
      this.alert = null
      if (this.process == false) {
        this.process = true;
        let data = this.settings;
        axios({url: this.$root.$data.api_url + '/api/settings/system', data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.get_settings();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;

            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.get_settings();
  },
  computed:{
    bank_supports:function (){
      return this.$root.$data.global_settings.bank_supports;
    },
  }
}
</script>

<style scoped>

</style>