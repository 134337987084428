<template>
    <div class="Merchant">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                  <h4 class="mb-0 mr-auto">{{$trans('Merchant settings')}}</h4>
                  <button @click="addForm = true" class="btn btn-primary btn-sm">
                    <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
                      <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    {{$trans('Add new')}}
                  </button>
                </div>
                <hr>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>{{$trans('Name')}}</th>
                            <th>{{$trans('Callback Url')}}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(m,index) in merchant" v-bind:key="index">
                            <td>{{m.name}}</td>
                            <td>
                                {{m.callback_url}}
                            </td>
                            <td>
                                <div class="table-controls">
                                    <a  @click="edit(m)" href="javascript:void(0)">
                                        <svg data-v-7db115a6="" xmlns="http://www.w3.org/2000/svg"
                                             width="20" height="20" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             stroke-linejoin="round" class="feather feather-edit-2">
                                            <path data-v-7db115a6="" d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                        </svg>
                                    </a>
                                    <a @click="destroy(m)" class="text-danger"
                                       href="javascript:void(0)">
                                        <svg data-v-7db115a6="" xmlns="http://www.w3.org/2000/svg"
                                             width="20" height="20" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             stroke-linejoin="round" class="feather feather-trash-2">
                                            <polyline data-v-7db115a6="" points="3 6 5 6 21 6"></polyline>
                                            <path data-v-7db115a6=""
                                                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line data-v-7db115a6="" x1="10" y1="11" x2="10" y2="17"></line>
                                            <line data-v-7db115a6="" x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </a>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <modal v-if="addForm" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Add merchant')}}
                    <button @click="addForm = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                             fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="addFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Callback URl')}} (*)</label>
                    <input v-model="addFields.callback_url" class="form-control" type="text">
                    <slot v-if="errors && errors['callback_url']">
                        <div class="text-danger" v-for="error in errors['callback_url']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <button @click="add_merchant" class="btn btn-primary">
                    <i v-if="process" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Add')}}
                </button>
            </slot>
        </modal>
        <modal v-if="editForm" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Edit merchant')}}
                    <button @click="editForm = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                             fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="updateFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Callback URl')}} (*)</label>
                    <input v-model="updateFields.callback_url" class="form-control" type="text">
                    <slot v-if="errors && errors['callback_url']">
                        <div class="text-danger" v-for="error in errors['callback_url']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <button @click="update" class="btn btn-primary">
                    <i v-if="process" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Update')}}
                </button>
            </slot>
        </modal>
        <modal v-if="showMerchantkey" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Merchant key')}}
                    <button @click="showMerchantkey = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                             fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </h5>
                <hr>
                <p>
                    {{$trans("Here is your new personal access token. This is the only time it will be shown so don't lose it! You may now use this token to make API requests.")}}
                </p>
                <div class="form-group">
                    <textarea disabled class="form-control" v-model="merchant_key" rows="5"></textarea>
                </div>
                <div class="form-group">
                    <label>Secret key</label>
                    <input disabled type="text" class="form-control" :value="secret_key">
                </div>
            </slot>
        </modal>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Merchant",
        data: function () {
            return {
                addForm: false,
                editForm: false,
                process: false,
                addFields: {
                    name: '',
                    callback_url: ''
                },
                errors: {},
                alert: null,
                showMerchantkey: false,
                merchant_key:'',
                secret_key:'',
                merchant:[],
                updateFields:{}
            }
        },
        methods: {
            get_merchant: function () {
                axios({url: this.$root.$data.api_url + '/api/merchant', method: 'GET'})
                    .then(resp => {
                        this.merchant = resp.data.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            add_merchant: function () {
                if (this.process == false) {
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/merchant', data: this.addFields, method: 'POST'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if (error_code == 0) {
                                this.addForm = false;
                                this.showMerchantkey = true;
                                this.merchant_key = resp.data.data.merchant_key;
                                this.secret_key = resp.data.data.secret_key;
                                this.get_merchant();
                            } else {
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            edit:function (m) {
                axios({url: this.$root.$data.api_url + '/api/merchant/'+m._id, method: 'GET'})
                    .then(resp => {
                        this.updateFields = resp.data.data;
                        this.editForm = true;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            update:function(){
                if (this.process == false) {
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/merchant/'+ this.updateFields._id, data: this.updateFields, method: 'PUT'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if (error_code == 0) {
                                this.editForm = false;
                                this.get_merchant();
                            } else {
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            destroy:function (m) {
                var actionConfirm = confirm("Are you sure?");
                if (actionConfirm === false) {
                    return false;
                }
                axios({url: this.$root.$data.api_url + '/api/merchant/' + m._id, method: 'DELETE'})
                    .then(() => {
                        this.get_merchant();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        created() {
            this.get_merchant();
        }
    }
</script>

<style scoped>

</style>