<template>
  <div class="auth-main">
    <div class="register-form">
      <form class="register" @submit.prevent="register">
        <h1>{{$trans('Register')}}</h1>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Username')}}</label>
          <input class="form-control" v-model="username" type="text"/>
          <slot v-if="errors && errors.username">
            <div class="text-danger" v-for="error in errors.username" v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Email')}}</label>
          <input class="form-control" v-model="email" type="email"/>
          <slot v-if="errors && errors.email">
            <div class="text-danger" v-for="error in errors.email" v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Password')}}</label>
          <input class="form-control"  v-model="password" type="password" />
          <slot v-if="errors && errors.password">
            <div class="text-danger" v-for="error in errors.password" v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button type="submit" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Register')}}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Register",
        data: function () {
            return {
                email: '',
                password: '',
                username:'',
                errors: {},
                alert: null,
                process: false
            }
        },
        methods: {
            register: function () {
                if (this.process == false) {
                    this.process = true;
                    let email = this.email
                    let password = this.password
                    let username = this.username
                    this.alert = null;
                    this.errors ={}
                    this.$store.dispatch('register', {email, password,username})
                        .then((rep) => {
                            this.process = false;
                          this.alert = {
                            type: 'success',
                            message: rep.data.message
                          }
                            setTimeout(()=>{

                              this.$router.push('/')
                            },5000);

                        })
                        .catch(err => {
                            this.process = false;
                            this.errors = err.data.errors || {};
                            this.alert = {
                                type: 'warning',
                                message: err.data.message
                            }
                        })
                }

            }
        }
    }
</script>

<style scoped>

</style>