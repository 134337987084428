<template>
  <div v-if="$can('fee-settings')" class="fee-settings">
    <div class="card">
        <div class="card-body">
            <h6 class="d-flex">
              <span class="mr-auto" style="line-height: 34px">{{$trans('Fee Settings')}}</span>
              <div v-if="$can('fee-settings')">
                <button @click="addForm = true" class="btn btn-primary btn-sm">Add New</button>
              </div>
            </h6>
            <hr>
            <slot v-if="delivery_fees && delivery_fees.data">
            <div class="table-responsive">
              <div class="list-info mb-3">
                <small>{{$trans('Results')}}: <strong>{{delivery_fees.data.length}}</strong> {{$trans('of')}} <strong>{{delivery_fees.total}}</strong></small>
              </div>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>{{$trans('Name')}}</th>
                  <th>{{$trans('Default')}}</th>
                  <th>{{$trans('Area of application')}}</th>
                  <th class="text-right">{{$trans('Actions')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(fee,index) in delivery_fees.data" v-bind:key="index">
                  <td>{{fee.name}}</td>
                  <td>{{fee.default}}</td>
                  <td>
                    <slot v-if="fee.area_of_application && fee.area_of_application.length">
                      <span v-for="(place,p) in fee.area_of_application" :key="p" class="badge badge-pill badge-success mt-1 ml-0 mr-1">{{place}}</span>
                    </slot>

                  </td>
                  <td class="text-right">
                    <b-dropdown
                        split
                        split-variant="outline-primary"
                        variant="primary"
                        :text="$trans('Actions')"
                        class="m-2"
                        size="sm"
                    >
                      <slot>
                        <b-dropdown-item  v-if="$can('edit-users')" @click="edit(fee)" href="#">{{$trans('Edit')}}</b-dropdown-item>
                        <b-dropdown-item  v-if="$can('delete-users')" @click="destroy(fee)" href="#">{{$trans('Delete')}}</b-dropdown-item>
                      </slot>
                    </b-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <paginate v-if="delivery_fees && delivery_fees.data && delivery_fees.last_page"
                      :page-count="delivery_fees.last_page"
                      :click-handler="paginate"
                      :prev-text="$trans('Previous')"
                      :next-text="$trans('Next')"
                      :page-class="'page-item'"
                      :prev-class="'page-item'"
                      :next-class="'page-item'"
                      :page-link-class="'page-link'"
                      :prev-link-class="'page-link'"
                      :next-link-class="'page-link'"
                      :container-class="'pagination'">
            </paginate>
          </slot>
        </div>
    </div>

    <modal v-bind:width="'900px'" v-bind:height="'100vh'" v-if="addForm">
      <slot slot="body">
        <h5 class="mb-3">
          Add Fee
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Name')}} (*)</label>
          <input v-model="addFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="fancy-checkbox">
            <label>
              <input true-value="yes"  false-value="no" v-model="addFields.default" type="checkbox">
              <span>{{$trans('Default Fee')}}</span>
            </label>
          </div>
        </div>
        <label class="d-block">{{$trans('Fee rule')}} (*)</label>
        <div v-for="(m,i) in methods" :key="i">
          <strong><u>{{m.name}}</u></strong>
          <table class="mb-0 table table-hover table-custom spacing5">
            <thead>
            <tr>
              <th>{{$trans('Condition')}}</th>
              <th class="w200">{{$trans('Value')}}</th>
              <th class="w200">{{$trans('Fixed')}}</th>
              <th v-if="m.key=='fee_by_order_total'" class="w200">{{$trans('Percent')}}</th>
              <th v-else class="w200">{{$trans('Cost')}}</th>
              <th class="w100"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rule,r) in fees[m.key]" :key="r">
              <td>{{rule.name}}</td>
              <td>
                <vue-numeric v-if="rule.condition=='less_than_or_equal'"
                             v-on:keyup.native="changeGreaterRules(m.key)" class="form-control"
                             currency-symbol-position="suffix"
                             v-bind:currency="m.unit"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['value']"
                             v-bind:precision="0">
                </vue-numeric>
                <span v-if="rule.condition=='greater'" class="form-control">
                                    <vue-numeric currency-symbol-position="suffix"
                                                 v-bind:currency="m.unit"
                                                 v-bind:read-only="true"
                                                 v-model="fees[m.key][r]['value']"
                                                 v-bind:precision="0">
                                </vue-numeric>
                                </span>

              </td>
              <td>
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'PHP'"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['fixed']"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td v-if="m.key=='fee_by_order_total'">
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'%'"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['percent']"
                             v-bind:precision="2">
                </vue-numeric>
              </td>
              <td v-else>
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'PHP/'+m.unit"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['cost']"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <button v-if="rule.condition=='less_than_or_equal'" @click="removeFeeRule(m.key,r)"
                        class="btn btn-sm btn-default text-danger">
                  <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="4"></td>
              <td class="text-right"><a @click="addFeeRule(m.key)" href="javascript:void(0)">Add rule</a>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div class="form-group">
          <label>{{$trans('COD')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.cod"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label>{{$trans('Round trip')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.round_trip"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label>{{$trans('Express delivery')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.express_delivery"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label >{{$trans('Area of application')}}</label>
          <Select2 v-model="addFields.area_of_application"  :settings="address_search_settings"  />
        </div>
        <button @click="store" class="btn btn-primary m-t-20">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add New')}}
        </button>
      </slot>
    </modal>
    <modal v-bind:width="'900px'" v-bind:height="'100vh'" v-if="editForm">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Edit Fee')}}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Name')}} (*)</label>
          <input v-model="updateFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="fancy-checkbox">
            <label>
              <input true-value="yes"  false-value="no" v-model="updateFields.default" type="checkbox">
              <span>{{$trans('Default Fee')}}</span>
            </label>
          </div>
        </div>
        <label class="d-block">{{$trans('Fee rule')}} (*)</label>
        <div v-for="(m,i) in methods" :key="i">
          <strong><u>{{m.name}}</u></strong>
          <table class="mb-0 table table-hover table-custom spacing5">
            <thead>
            <tr>
              <th>{{$trans('Condition')}}</th>
              <th class="w200">{{$trans('Value')}}</th>
              <th class="w200">{{$trans('Fixed')}}</th>
              <th v-if="m.key=='fee_by_order_total'" class="w200">{{$trans('Percent')}}</th>
              <th v-else class="w200">{{$trans('Cost')}}</th>
              <th class="w100"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(rule,r) in fees[m.key]" :key="r">
              <td>{{rule.name}}</td>
              <td>
                <vue-numeric v-if="rule.condition=='less_than_or_equal'"
                             v-on:keyup.native="changeGreaterRules(m.key)" class="form-control"
                             currency-symbol-position="suffix"
                             v-bind:currency="m.unit"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['value']"
                             v-bind:precision="0">
                </vue-numeric>
                <span v-if="rule.condition=='greater'" class="form-control">
                                    <vue-numeric currency-symbol-position="suffix"
                                                 v-bind:currency="m.unit"
                                                 v-bind:read-only="true"
                                                 v-model="fees[m.key][r]['value']"
                                                 v-bind:precision="0">
                                </vue-numeric>
                                </span>

              </td>
              <td>
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'PHP'"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['fixed']"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td v-if="m.key=='fee_by_order_total'">
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'%'"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['percent']"
                             v-bind:precision="2">
                </vue-numeric>
              </td>
              <td v-else>
                <vue-numeric class="form-control" currency-symbol-position="suffix"
                             v-bind:currency="'PHP/'+m.unit"
                             v-bind:read-only="false"
                             v-model="fees[m.key][r]['cost']"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <button v-if="rule.condition=='less_than_or_equal'" @click="removeFeeRule(m.key,r)"
                        class="btn btn-sm btn-default text-danger">
                  <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="4"></td>
              <td class="text-right"><a @click="addFeeRule(m.key)" href="javascript:void(0)">Add rule</a>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div class="form-group">
          <label>{{$trans('COD')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.cod"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label>{{$trans('Round trip')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.round_trip"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label>{{$trans('Express delivery')}}</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="PHP"
                       v-bind:read-only="false"
                       v-model="fees.express_delivery"
                       v-bind:precision="0">
          </vue-numeric>
        </div>
        <div class="form-group">
          <label >{{$trans('Area of application')}}</label>
          <Select2 v-model="updateFields.area_of_application" :options="place_selected"  :settings="address_search_settings"  />
        </div>
        <button @click="update" class="btn btn-primary m-t-20">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Update')}}
        </button>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
name: "Fee",
  data:function (){
    return {
      fees: {
        fee_by_weight: [],
        fee_by_volume: [],
        fee_by_distance: [],
        fee_by_order_total: [],
        cod: 0,
        round_trip: 0,
        express_delivery: 0
      },
      process: false,
      addForm: false,
      editForm: false,
      addFields: {
        name: '',
        default: 'no',
        area_of_application:[]
      },
      updateFields:null,
      errors: {},
      alert:null,
      methods: [
        {key: 'fee_by_weight', name: 'Fee by weight', unit: 'kg'},
        {key: 'fee_by_volume', name: 'Fee by volume', unit: 'm3'},
        {key: 'fee_by_distance', name: 'Fee by distance', unit: 'km'},
        {key: 'fee_by_order_total', name: 'Fee by order total', unit: 'PHP'},
      ],
      filterQuery:{
        page:1
      },
      delivery_fees:null,
      address_search_settings:{
        ajax:{
          url: this.$root.$data.api_url + '/api/address/search',
          dataType: 'json',
          processResults: function (data) {
            let addresses = data.predictions;
            let results=[]
            addresses.forEach((el)=>{
              let item ={
                id:el.description,
                text:el.description
              }
              results.push(item)
            })
            return {results:results}
          },
        },
        multiple:true,
        placeholder:'Enter keyword here!',
        minimumInputLength:3
      },
    }
  },
  methods:{
    addFeeRule: function (type) {
      var rule = {
        name: 'Less than or equal',
        condition: 'less_than_or_equal',
        value: 0,
        cost: 0,
        fixed: 0,
      }
      this.fees[type].push(rule);
      var rule_greater = {
        name: 'Greater',
        condition: 'greater',
        value: 0,
        cost: 0,
        fixed:0,
      };

      var rules = this.fees[type];
      rules.forEach(function (item, index) {
        if (item.condition == 'greater') {
          rule_greater = item;
          rules.splice(index, 1);
        }
      });

      this.fees[type].push(rule_greater);
      this.changeGreaterRules(type);
    },
    removeFeeRule: function (type, index) {
      var rules = this.fees[type];
      var rule = this.fees[type][index];
      if (rule.condition != 'greater') {
        rules.splice(index, 1);
      }
      this.fees[type] = rules;
      this.changeGreaterRules(type);
    },
    changeGreaterRules: function (type) {
      var rules = this.fees[type];
      var max = 0;
      rules.forEach(function (item, index) {
        if (item.condition != 'greater') {
          if (max < item.value) {
            max = item.value;
          }
        } else {
          rules[index]['value'] = max;
        }
      });
      this.fees[type] = rules;
    },
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/fee',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.delivery_fees = resp.data;

          })
          .catch(err => {
            console.log(err)
          })
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    store:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        this.addFields.rules = this.fees;
        axios({url: this.$root.$data.api_url + '/api/fee', data:this.addFields,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (fee){
      axios({url: this.$root.$data.api_url + '/api/fee/'+fee._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.editForm = true;
            this.fees = resp.data.data.rules
          })
          .catch(err => {
            console.log(err)
          })
    },
    destroy:function (fee){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/fee/' + fee._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
    update:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        this.updateFields.rules = this.fees;
        axios({url: this.$root.$data.api_url + '/api/fee/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.editForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.index()
  },
  computed:{
    place_selected:function (){
        if( !this.updateFields){
          return  []
        }
        let area_of_application = this.updateFields.area_of_application
        let results =[];
        area_of_application.forEach((el)=>{
          let item ={
            id:el,
            text:el
          }
          results.push(item)
        })
        return  results;
    }
  }
}
</script>

<style scoped>

</style>