<template>
    <div class="settings">
      <div class="card">
          <div class="card-header">
            <h2 class="mr-auto">{{$trans('Settings')}}</h2>
          </div>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" >
            <a class="nav-item nav-link" @click="tabActive = 'system'" v-bind:class="{'active':tabActive=='system'}" href="javascript:void(0)">{{$trans('General')}}</a>
            <a v-if="$can('fee-settings')" class="nav-item nav-link" @click="tabActive = 'fee'" v-bind:class="{'active':tabActive=='fee'}" href="javascript:void(0)">{{$trans('Fee')}}</a>
            <a v-if="$can('address-settings')" class="nav-item nav-link" @click="tabActive = 'OfficeAddress'" v-bind:class="{'active':tabActive=='OfficeAddress'}" href="javascript:void(0)">{{$trans('Office Address')}}</a>
            <a class="nav-item nav-link" @click="tabActive = 'merchant'" v-bind:class="{'active':tabActive=='merchant'}" href="javascript:void(0)">{{$trans('Merchant')}}</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div v-if="tabActive == 'system'" class="tab-pane active">
            <system-settings></system-settings>
          </div>
          <div v-if="tabActive == 'fee'" class="tab-pane active">
            <fee-settings></fee-settings>
          </div>
          <div v-if="tabActive == 'merchant'" class="tab-pane active">
            <merchant-settings></merchant-settings>
          </div>
          <div v-if="tabActive == 'OfficeAddress'" class="tab-pane active">
            <OfficeAddress></OfficeAddress>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "Settings",
        data:function () {
            return {
                tabActive:'system'
            }
        }
    }
</script>

<style scoped>

</style>