<template>
  <div v-if="$can('view-shippers')" class="card">
    <div class="card-header d-flex">
      <h2 class="mr-auto ">{{ $trans('Shippers') }}</h2>
    </div>
    <GmapMap :center="center" :zoom="18" map-type-id="roadmap" style="width: 100%; height: calc(100vh - 80px)">
      <div v-for="(locate,index) in shippers_locate" v-bind:key="index">
        <gmap-custom-marker   v-if="locate.marker && locate.marker.lat &&  locate.marker.lng" class="marker shipper-marker" style="" :marker="locate.marker" alignment="top">
          <div class="name">{{locate.name}}</div>
          <div class="icon"></div>
        </gmap-custom-marker>
      </div>
    </GmapMap>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Shipper",
  data:function (){
      return {
        shippers:null,
        filterQuery:{

        },
        center:{
            lat:14.563008,
            lng:121.014024
        }

      }
  },
  methods:{
      index:function (){
        axios({url: this.$root.$data.api_url + '/api/shippers/locate',params: this.filterQuery, method: 'GET'})
            .then(resp => {
              this.shippers = resp.data;
            })
            .catch(err => {
              console.log(err)
            })
      }
  },
  created() {
    this.index()
  },
  computed:{
    shippers_locate:function (){
        if( !this.shippers){
            return []
        }
        let shippers_locate = []
        let shippers = this.shippers
      shippers.forEach(el=>{
          let item ={
              name:el.name,
              marker:{
                lat:el.lat,
                lng:el.lng
              }
          }
          shippers_locate.push(item)
      });
      return  shippers_locate;
    }
  }
}
</script>

<style scoped>

</style>