<template>
  <div v-if="$can('address-settings')" class="card">
    <div class="card-body">
      <h6 class="d-flex">
        <span class="mr-auto" style="line-height: 34px">{{$trans('Office Address Settings')}}</span>
        <div>
          <button @click="addForm = true" class="btn btn-primary btn-sm">Add New</button>
        </div>
      </h6>
      <hr>
      <slot v-if="addresses && addresses.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{$trans('Results')}}: <strong>{{addresses.data.length}}</strong> {{$trans('of')}} <strong>{{addresses.total}}</strong></small>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{$trans('Name')}}</th>
              <th>{{$trans('Phone')}}</th>
              <th>{{$trans('Address')}}</th>
              <th class="text-right">{{$trans('Actions')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(address,index) in addresses.data" v-bind:key="index">
              <td>{{address.name}}</td>
              <td>{{address.phone}}</td>
              <td>
                <slot v-if="address.address!=''">{{address.address}},</slot> {{address.place}}
              </td>
              <td class="text-right">
                <b-dropdown
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :text="$trans('Actions')"
                    class="m-2"
                    size="sm"
                >
                  <slot>
                    <b-dropdown-item  v-if="$can('delete-users')" @click="destroy(address)" href="#">{{$trans('Delete')}}</b-dropdown-item>
                  </slot>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="delivery_fees && delivery_fees.data && delivery_fees.last_page"
                  :page-count="delivery_fees.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
    <modal v-bind:width="'900px'" v-bind:height="'100vh'" v-if="addForm">
      <slot slot="body">
        <h5 class="mb-3">
          Add Fee
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Name')}} (*)</label>
          <input v-model="addFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Phone')}} (*)</label>
          <input v-model="addFields.phone" class="form-control" type="text">
          <slot v-if="errors && errors['phone']">
            <div class="text-danger" v-for="error in errors['phone']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Street and/or house number')}} (*)</label>
          <Select2  v-model="addFields.place_id" :settings="search_address_settings"  />
          <slot v-if="errors && errors['place_id']">
            <div class="text-danger" v-for="error in errors['place_id']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Unit/Room')}}</label>
          <input v-model="addFields.address" class="form-control" type="text">
          <slot v-if="errors && errors['address']">
            <div class="text-danger" v-for="error in errors['address']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="store" class="btn btn-primary m-t-20">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add New')}}
        </button>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OfficeAddress",
  data:function (){
      return {
        addForm:false,
        alert:null,
        process:false,
        addFields:{
          name:'',
          phone:'',
          address:'',
          place_id:''
        },
        addresses:null,
        errors:{},
        search_address_settings: {
          ajax: {
            url: this.$root.$data.api_url + '/api/v2/address/search',
            dataType: 'json',
            processResults: function (data) {
              let list = data.data;
              let results = []
              list.forEach((el) => {
                let address = el.address
                let item = {
                  id: address.id,
                  text: address.formatted_address
                }
                results.push(item)
              })
              return {results: results}
            },

          },
          multiple:false,
        },
        filterQuery:{
          page:1
        }
      }
  },
  methods:{
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/office_address',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.addresses = resp.data;

          })
          .catch(err => {
            console.log(err)
          })
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    store:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/office_address', data:this.addFields,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (address){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/office_address/' + address._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.index()
  }
}
</script>

<style scoped>

</style>