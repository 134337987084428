<template>
  <div class="orders">
    <div class="card-header d-flex">
      <h2 class="mr-auto ">{{ $trans('Orders') }}</h2>
    </div>
    <div class="d-flex mb-3">
      <input @change="filter" v-model="filterQuery.keyword" type="text" placeholder="Order ID"
             class="form-control w-150 mr-3">
      <select @change="filter" v-model="filterQuery.status" class="form-control w-150 mr-3">
        <option value="all">Status</option>
        <option value="0">Initialization</option>
        <option value="1">Processing</option>
        <option value="2">Received</option>
        <option value="3">On Delivery</option>
        <option value="4">Delivered</option>
        <option value="5">Completed</option>
      </select>
      <Select2 class="mr-3 w-150" @change="filter" v-model="filterQuery.user_id" :settings="user_filter_settings"  />
      <select @change="filter" v-model="filterQuery.filterby_date" class="form-control w-150 mr-3">
        <option value="all">All date</option>
        <option value="today">Today</option>
        <option value="week">This Week</option>
        <option value="month">This Month</option>
        <option value="year">This Year</option>
      </select>
    </div>
    <div class="card-body">
      <slot v-if="orders && orders.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ orders.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ orders.total }}</strong></small>
          </div>
          <table class="table table-hover">
            <thead>
            <tr>
              <th>{{ $trans('ID') }}</th>
              <th>{{ $trans('Client') }}</th>
              <th class="nowrap">{{ $trans('Client Order ID') }}</th>
              <th>{{ $trans('Shipper') }}</th>
              <th class="nowrap">{{ $trans('COD Amount') }}</th>

              <th>{{ $trans('Pickup Type') }}</th>
              <th>{{ $trans('Pickup address') }}</th>
              <th>{{ $trans('Destination address') }}</th>
              <th>{{ $trans('Cost') }}</th>
              <th>{{ $trans('Status') }}</th>
              <th>{{ $trans('Date') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order,index) in orders.data" v-bind:key="index">
              <td>{{ order._id }}</td>
              <td class="text-capitalize">
                <slot v-if="order.user">{{order.user.username}}</slot>
              </td>
              <td>
                  <slot v-if="order.order_id">{{order.order_id}}</slot>
                  <slot v-else>-</slot>
              </td>
              <td>
                <slot v-if="order.shipper">{{ order.shipper.name }}</slot>
                <slot v-else>-</slot>
              </td>
              <td>
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="PHP"
                    v-bind:value="order.cod_amount"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>

              <td class="text-uppercase nowrap">
                <slot v-if="order.pickup_type=='home'">CLIENT OFFICE</slot>
                <slot v-if="order.pickup_type=='office'">OFFICE</slot>
              </td>
              <td>
                {{ order.pickup_address.name }} - {{ order.pickup_address.phone }}<br>
                {{ order.pickup_address.address }} {{ order.pickup_address.place }}
              </td>
              <td>
                {{ order.destination_address.name }} - {{ order.destination_address.phone }}<br>
                {{ order.destination_address.address }} {{ order.destination_address.place }}
              </td>
              <td class="nowrap">
                <slot v-if="order.delivery_fee">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency="PHP"
                      v-bind:value="order.delivery_fee.total"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </slot>
                <slot v-else>-</slot>

              </td>
              <td>
                <span v-if="order.status == -3" class="badge badge-primary badge-pill returned">{{ $trans('Returned') }}</span>
                <span v-if="order.status == -2" class="badge badge-warning badge-pill returning">{{ $trans('Returning') }}</span>
                <span v-if="order.status == -1" class="badge badge-danger badge-pill cancel">{{ $trans('Cancel') }}</span>
                <span v-if="order.status == 0" class="badge badge-default badge-pill initialization">{{ $trans('Initialization') }}</span>
                <span v-if="order.status == 1" class="badge badge-info badge-pill processing">{{ $trans('Processing') }}</span>
                <span v-if="order.status == 2" class="badge badge-success badge-pill received">{{ $trans('Received') }}</span>
                <span v-if="order.status == 3" class="badge badge-success badge-pill on-delivery">{{ $trans('On Delivery') }}</span>
                <span v-if="order.status == 4" class="badge badge-success badge-pill delivered">{{ $trans('Delivered') }}</span>
                <span v-if="order.status == 5" class="badge badge-success badge-pill completed">{{ $trans('Completed') }}</span>
              </td>
              <td class="nowrap">{{ order.created_at }}</td>
              <td class="text-right">
                <b-dropdown
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :text="$trans('Actions')"
                    class="m-2"
                    size="sm"
                >
                  <slot>
                    <b-dropdown-item @click="detail(order)"   href="#">{{$trans('Detail')}}</b-dropdown-item>
                    <b-dropdown-item @click="re_callback(order)"   href="#">{{$trans('Re-Callback')}}</b-dropdown-item>
                  </slot>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="orders && orders.data && orders.last_page"
                  :page-count="orders.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>

    <modal v-bind:width="'1000px'" v-if="detailForm">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Order Detail')}}
          <button @click="detailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div v-if="order" class="row">
            <div class="col-sm-8">
              <table class="table table-striped">
                <tr>
                  <th>{{$trans('Order ID')}}</th>
                  <td>{{order._id}}</td>
                </tr>
                <tr>
                  <th>{{$trans('Status')}}</th>
                  <td>
                    <span v-if="order.status == -3" class="badge badge-primary badge-pill returned">{{ $trans('Returned') }}</span>
                    <span v-if="order.status == -2" class="badge badge-warning badge-pill returning">{{ $trans('Returning') }}</span>
                    <span v-if="order.status == -1" class="badge badge-danger badge-pill cancel">{{ $trans('Cancel') }}</span>
                    <span v-if="order.status == 0" class="badge badge-default badge-pill initialization">{{ $trans('Initialization') }}</span>
                    <span v-if="order.status == 1" class="badge badge-info badge-pill processing">{{ $trans('Processing') }}</span>
                    <span v-if="order.status == 2" class="badge badge-success badge-pill received">{{ $trans('Received') }}</span>
                    <span v-if="order.status == 3" class="badge badge-success badge-pill on-delivery">{{ $trans('On Delivery') }}</span>
                    <span v-if="order.status == 4" class="badge badge-success badge-pill delivered">{{ $trans('Delivered') }}</span>
                    <span v-if="order.status == 5" class="badge badge-success badge-pill completed">{{ $trans('Completed') }}</span>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Shipper')}}</th>
                  <td>
                    <slot v-if="order.shipper">{{ order.shipper.name }}- {{ order.shipper.phone }}</slot>
                    <slot v-else>-</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Cod amount')}}</th>
                  <td>
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency="PHP"
                        v-bind:value="order.cod_amount"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Fee Payer')}}</th>
                  <td>{{order.fee_payer}}</td>
                </tr>
                <tr>
                  <th style="vertical-align: top">{{$trans('Pickup')}}</th>
                  <td>
                    {{ order.pickup_address.name }} - {{ order.pickup_address.phone }}<br>
                    {{ order.pickup_address.address }} {{ order.pickup_address.place }}
                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Destination')}}</th>
                  <td>
                    {{ order.destination_address.name }} - {{ order.destination_address.phone }}<br>
                    {{ order.destination_address.address }} {{ order.destination_address.place }}
                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Packages')}}</th>
                  <td>
                    <table class="table">
                      <thead>
                      <tr>
                        <th>{{$trans('Name')}}</th>
                        <th>{{$trans('Type')}}</th>
                        <th>{{$trans('Value')}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(pack,p) in order.packages" :key="p">
                        <td>{{pack.name}}</td>
                        <td>{{pack.type}}</td>
                        <td>{{pack.value}}</td>
                      </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Cost')}}</th>
                  <td>
                    <table class="table table-hover table-custom spacing5">
                      <tbody v-if="order.delivery_fee && order.delivery_fee.detail">
                        <tr v-for="(cost,c) in order.delivery_fee.detail" :key="c">
                          <td>{{cost.name}}</td>
                          <td>
                            <vue-numeric
                                currency-symbol-position="suffix"
                                currency="PHP"
                                v-bind:value="cost.amount"
                                v-bind:read-only="true"
                                v-bind:precision="0">
                            </vue-numeric>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td><strong>{{$trans('Total')}}:</strong></td>
                        <td>
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency="PHP"
                              v-bind:value="order.delivery_fee.total"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Note')}}</th>
                  <td>{{order.note}}</td>
                </tr>
                <tr>
                    <th style="vertical-align: top">{{$trans('Tracking Histories')}}</th>
                    <td>
                        <slot v-if="order.tracking_histories">
                            <span v-for="(log,l) in order.tracking_histories" :key="l" class="d-block">{{log}}</span>
                        </slot>
                    </td>
                </tr>
                <tr>
                  <th style="vertical-align: top">{{ $trans('Callback') }}</th>
                  <td>
                    <slot v-if="order.callback">
                      <span class="d-block"><strong>Status:</strong> {{ order.callback.status }}</span>
                      <span class="d-block"><strong>Url:</strong> {{ order.callback.url }}</span>
                      <span class="d-block"><strong>Http Code:</strong> {{ order.callback.http_code }}</span>
                      <span class="d-block"><strong>Time:</strong> {{ order.callback.time }}</span>
                      <span class="d-block">
                        <strong>{{$trans('Input')}}:</strong>
<pre>
{{ order.callback.input }}
</pre>
                      </span>
                      <span class="d-block">
                        <strong>{{$trans('Response')}}:</strong>
<pre>
{{ order.callback.response }}
</pre>
                      </span>
                    </slot>
                    <span class="d-block">
                      <button @click="re_callback(order)" class="btn btn-primary">
                        <i v-if="process" class="fa fa-spinner fa-spin"></i>
                        {{$trans('Re-Callback')}}
                      </button>
                  </span>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-sm-4">
              <qr-code class="mb-3" :text="order._id" :size="100"></qr-code>
              <button  v-print="printObj" class="btn btn-sm btn-primary mr-3">{{$trans('Print')}}</button>
              <router-link class="btn btn-sm btn-info" :to="'/tracking/'+order._id">{{$trans('Tracking')}}</router-link>
              <hr>
              <div v-if="$can('confirm-orders')" class="form-actions mb-4">
                <slot v-if="order.status== 0">
                  <div class="alert alert-warning">
                    {{$trans('When you receive the goods, you will definitely charge a full fee')}}
                  </div>
                  <button @click="confirm(order,'received')" class="btn btn-primary mr-2">
                    <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Received')}}
                  </button>
                </slot>

                <slot v-if="order.status== -2">
                  <button @click="confirm(order,'returned')" class="btn btn-primary mr-2">
                    <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Returned')}}
                  </button>
                </slot>
                <slot v-if="order.status == 0 || order.status == 1">
                  <button @click="confirm(order,'cancel')" class="btn btn-danger mr-2">
                    <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Cancel')}}
                  </button>
                </slot>
                <button @click="confirm(order,'completed')" class="btn btn-success mr-2">
                  <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                  {{$trans('Completed')}}
                </button>
              </div>
              <slot v-if="order.shipper && shiper_pay">
                <h6>{{$trans('Shipper need payment')}}</h6>
                <hr>
                <table class="table table-hover table-custom spacing5">
                  <thead>
                  <tr>
                    <th>Type</th>
                    <th class="text-right">Value</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in shiper_pay.data" :key="i">
                    <td>{{item.type}}</td>
                    <td class="text-right">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency="PHP"
                          v-bind:value="item.amount"
                          v-bind:read-only="true"
                          v-bind:precision="0">
                      </vue-numeric>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th>Total:</th>
                    <td class="text-right">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency="PHP"
                          v-bind:value="shiper_pay.total"
                          v-bind:read-only="true"
                          v-bind:precision="0">
                      </vue-numeric>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </slot>
            </div>
            <div style="display: none">
              <div id="delivery-receipt" class="delivery-receipt">
                <div class="container">
                  <div class="print-page">
                    <div class="print-head">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="logo">
                            <img :src="require('../assets/logo.png')" alt="">
                          </div>
                          <div class="company-info">
                            <span>{{ company_info.address }}</span>
                            <span><strong>Phone:</strong> {{ company_info.phone }}</span>
                            <span><strong>Email:</strong> {{ company_info.email }}</span>
                            <span><strong>Website:</strong> {{ company_info.website }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="head-info text-right">
                            <span class="date">{{ order.created_at }}</span>
                            <span class="bar-code text-right">
                               <qr-code class="mb-3" :text="order._id" :size="100"></qr-code>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="print-title">
                      <span class="text">{{$trans('Delivery Note')}}</span>
                    </div>

                    <div class="print-shipping">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="address">
                            <span><strong>{{$trans('Pickup')}}:</strong></span>
                            <span>{{ order.pickup_address.address }}, {{ order.pickup_address.place }}</span>
                            <span><strong>Name:</strong> {{ order.pickup_address.name }}</span>
                            <span><strong>Phone:</strong> {{ order.pickup_address.phone }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="address">
                            <span><strong>{{$trans('Destination')}}:</strong></span>
                            <span>{{ order.destination_address.address }}, {{ order.destination_address.place }}</span>
                            <span><strong>Name:</strong> {{ order.destination_address.name }}</span>
                            <span><strong>Phone:</strong> {{ order.destination_address.phone }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="print-body">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>{{$trans('Package name')}}</th>
                          <th>{{$trans('Type')}}</th>
                          <th>{{$trans('Value')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(pack,p) in order.packages" :key="p">
                          <td>{{ pack.name }}</td>
                          <td>{{ pack.type }}</td>
                          <td>{{ pack.value }}</td>
                        </tr>
                        </tbody>
                      </table>
                      <div>
                        <strong>{{$trans('Total Costs')}}: </strong>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency="PHP"
                            v-bind:value="order.delivery_fee.total"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                      </div>
                      <div>
                        <strong>{{$trans('COD amount')}}: </strong>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency="PHP"
                            v-bind:value="order.cod_amount"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                      </div>
                      <div>
                        <strong>{{$trans('Note')}}: </strong>
                        {{ order.note }}
                      </div>
                    </div>
                    <div class="print-footer">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="signature">
                            {{$trans('Receiver')}}:
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from 'pusher-js';

export default {
  name: "Order",
  data: function () {
    return {
      orders: null,
      filterQuery: {
        page: 1,
        filterby_date: 'all',
        status: 'all',
      },
      process:false,
      detailForm:false,
      alert:null,
      order_detail:null,
      processActions:false,
      order:null,
      user_filter_settings: {
        ajax: {
          url: this.$root.$data.api_url + '/api/ajax/search/users',
          dataType: 'json',
          processResults: function (data) {
            let users = data;
            let results = []
            users.forEach((el) => {
              let user = {
                id: el._id,
                text: el.username
              }
              results.push(user)
            })
            return {results: results}
          },

        },
        multiple:false,
        placeholder:"Select a user"
      },
      printLoading: true,
      printObj: {
        id: "delivery-receipt",
        preview: true,
        previewTitle: 'Delivery Receipt', // The title of the preview window. The default is 打印预览
        popTitle: 'x',
        extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
        extraHead: '<style>.print-head .logo{\n' +
            '    margin-bottom: 20px;\n' +
            '    font-size: 30px;\n' +
            '    line-height: normal;\n' +
            '}\n' +
            '.company-info >span{\n' +
            '    display: block;\n' +
            '}\n' +
            '.print-page{\n' +
            '    background-color: #fff;\n' +
            '    font-size: 16px;\n' +
            'width: 950px;\n' +
            'margin: 0 auto;\n' +
            'padding: 20px 50px;' +
            '}\n' +
            '.print-title{\n' +
            '    margin-top: 50px;\n' +
            '    margin-bottom: 50px;\n' +
            '    position: relative;\n' +
            '}\n' +
            '.print-title{\n' +
            '    border-bottom: 5px solid #000000;\n' +
            '}\n' +
            '.print-title  .text{\n' +
            '    display: inline-block;\n' +
            '    font-size: 30px;\n' +
            '    padding: 2px 10px;\n' +
            '    position: absolute;\n' +
            '    z-index: 2;\n' +
            '    background-color: #fff;\n' +
            '    line-height: normal;\n' +
            '    left: 50px;\n' +
            '    top: 50%;\n' +
            '    transform: translateY(-50%);\n' +
            '}\n' +
            '.print-title:before{\n' +
            '    content: \'\';\n' +
            '    height: 5px;\n' +
            '    width: 100%;\n' +
            '    background-color: #000;\n' +
            '    top: 50%;\n' +
            '    transform: translateY(-50%);\n' +
            '    position: absolute;\n' +
            '    z-index: 1;\n' +
            '}\n' +
            '.print-shipping>span{\n' +
            '    display: block;\n' +
            '}\n' +
            '.print-shipping{\n' +
            '    margin-bottom: 10px;\n' +
            '}\n' +
            '.print-page table{\n' +
            '    width: 100%;\n' +
            '}\n' +
            '.print-page tbody tr:nth-of-type(2n+1) {\n' +
            '    background-color: rgba(0, 0, 0, 0.05);\n' +
            '}\n' +
            '.print-page table thead th{\n' +
            '    background-color: #000;\n' +
            '    color: #fff;\n' +
            '}\n' +
            '.print-page table tr td,\n' +
            '.print-page table tr th {\n' +
            '    vertical-align: middle;\n' +
            '    white-space: nowrap;\n' +
            '    border-color: #2f3338;\n' +
            '    padding: 10px;\n' +
            '}\n' +
            '.address>span{\n' +
            '    display: block;\n' +
            '}' +
            '.head-info img{display: inline-block!important;\n float: right;\n}</style> ',
        previewPrintBtnLabel:"Print",
      }
    }
  },
  methods: {
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/orders/all', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.orders = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail: function (order) {
        axios({url: this.$root.$data.api_url + '/api/orders/detail/'+order._id, method: 'GET'})
          .then(resp => {
            this.order = resp.data.data;
            this.detailForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    re_callback:function (order){
      if (this.process == false) {
        this.process = true;
        axios({url: this.$root.$data.api_url + '/api/orders/re_callback/'+order._id, data:[],method: 'POST'})
            .then(resp => {
              this.process = false;
              this.detail(resp.data.data)
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }

    },
    print:function (id) {
      this.$htmlToPaper(id);
    },
    confirm:function (order,action){
      if (this.processActions == false) {
        var actionConfirm = confirm("Are you sure?");
        if (actionConfirm === false) {
          this.processActions = false;
          return false;
        }
        this.processActions = true;
        this.errors = {}
        var data = {
          action: action
        }
        axios({url: this.$root.$data.api_url + '/api/orders/confirm/'+order._id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.index();
                this.detail(resp.data.data)
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.processActions = false;
            })
            .catch(err => {
              console.log(err)
              this.processActions = false;
            })
      }
    }
  },
  created() {
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    });
    const channel = pusher.subscribe('order-channel');
    channel.bind('order-event',  (e)=> {
      if (this.orders && this.orders.data){
        if( e.action=='created'){
          this.orders.data.unshift(e.order);
        }else{
          var arr = this.orders.data;
          var tmp_arr = [];
          var current_id = e.order._id;
          arr.forEach(function(el, index) {
            if(current_id == el._id){
              tmp_arr[index] = e.order;
            }else{
              tmp_arr[index] = el;
            }
          });
          this.orders.data = tmp_arr;
        }
      }
    });
    this.index()
  },
  computed: {
    company_info:function (){
      return this.$root.$data.global_settings.company_info
    },
    shiper_pay: function () {
      if (!this.order) {
        return {};
      }
      const order = this.order;
      var total_cost = parseFloat(order.delivery_fee.total);
      var cod_amount = parseFloat(order.cod_amount);
      if (order.pickup_type == 'office') {
        total_cost = 0;
      }
      if (order.order_return) {
        cod_amount = 0;
      }
      var data = {
        data: [
          {
            type: 'COD',
            amount: cod_amount
          },
          {
            type: 'Delivery fee',
            amount: total_cost
          }
        ],
        total: parseFloat(cod_amount + total_cost)
      }
      return data;
    }
  },

}
</script>

<style scoped>

</style>