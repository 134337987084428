<template>
  <div class="auth-main">
    <div class="form reset-password">
      <form class="login" @submit.prevent="reset_password">
        <h1>{{$trans('Reset password')}}</h1>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Password')}}</label>
          <input class="form-control"  v-model="password" type="password"/>
          <slot v-if="errors && errors['password']">
            <div class="text-danger" v-for="error in errors['password']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Confirm Password')}}</label>
          <input class="form-control"  v-model="password_confirmation" type="password"/>
          <slot v-if="errors && errors['password_confirmation']">
            <div class="text-danger" v-for="error in errors['password_confirmation']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button class="btn btn-primary" type="submit">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Reset password')}}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ResetPassword",
        props: {
            token: {
                required: true,
                type: String
            },
            email: {
                required: true,
                type: String
            }
        },
        data:function () {
            return {
                process:false,
                alert:null,
                errors:{},
                password:'',
                password_confirmation:''
            }
        },
        methods:{
            reset_password:function () {
                if (this.process == false) {
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    let data ={
                        password:this.password,
                        password_confirmation:this.password_confirmation,
                        email: this.email,
                        token:this.token
                    }
                    axios({url: this.$root.$data.api_url + '/api/auth/reset-password', data: data, method: 'POST'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if (error_code == 0) {
                                this.alert = {
                                    type: 'success',
                                    message: resp.data.message
                                }
                            } else {
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            }
        },
        created() {
            if( this.$root.isLoggedIn){
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>

</style>