<template>
    <div class="users">
        <div class="card">
            <div class="card-header d-flex">
                <h2 class="mr-auto ">{{$trans('Users')}}</h2>
                <button v-if="$can('add-users')" @click="addForm = true" class="btn btn-primary btn-sm">
                    <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
                    {{$trans('Add new')}}
                </button>
            </div>
          <div class="d-flex mb-3">
            <input @change="filter" v-model="filterQuery.keyword" :placeholder="'username,email,..'" type="text"
                   class="form-control mr-3 w-300">
          </div>
            <div class="card-body">
                <slot v-if="users && users.data">
                    <div class="table-responsive">
                      <div class="list-info mb-3">
                        <small>{{$trans('Results')}}: <strong>{{users.data.length}}</strong> {{$trans('of')}} <strong>{{users.total}}</strong></small>
                      </div>
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>{{$trans('Name')}}</th>
                                <th>{{$trans('Username')}}</th>
                                <th>{{$trans('Phone')}}</th>
                                <th>{{$trans('Email')}}</th>
                                <th>{{$trans('Roles')}}</th>
                                <th>{{$trans('Balance')}}</th>
                                <th>{{$trans('Status')}}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user,index) in users.data" v-bind:key="index">
                                <td>{{user.name}}</td>
                                <td>{{user.username}}</td>
                                <td>{{user.phone}}</td>
                                <td>{{user.email}}</td>
                                <td>
                                  <span v-for="(role,i) in user.roles" v-bind:key="i" class="mr-1 mt-1 badge badge-primary badge-pill">
                                      {{role.name}}
                                  </span>
                                  <span class="mr-1 mt-1 badge badge-danger badge-pill" v-if="user.agency">{{$trans('Agency')}}</span>
                                </td>
                                <td>
                                    <vue-numeric v-if="user.wallet"
                                                 currency-symbol-position="suffix"
                                                 currency="đ"
                                                 v-bind:value="user.wallet.amount"
                                                 v-bind:read-only="true"
                                                 v-bind:precision="0">
                                    </vue-numeric>
                                </td>
                                <td>
                                    <span v-if="user.status" class="badge badge-primary badge-pill">{{$trans('Active')}}</span>
                                    <span v-if="!user.status" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
                                </td>
                              <td class="text-right">
                                <b-dropdown
                                    split
                                    split-variant="outline-primary"
                                    variant="primary"
                                    :text="$trans('Actions')"
                                    class="m-2"
                                    size="sm"
                                >
                                  <slot>
                                    <b-dropdown-item  v-if="$can('edit-users')" @click="edit(user)" href="#">{{$trans('Edit')}}</b-dropdown-item>
                                    <b-dropdown-item  v-if="$can('delete-users')" @click="destroy(user)" href="#">{{$trans('Delete')}}</b-dropdown-item>
                                    <b-dropdown-item  v-if="$can('add-agency')" :disabled="user.agency" @click="set_agency(user)" href="#">{{$trans('Set Agency')}}</b-dropdown-item>
                                  </slot>
                                </b-dropdown>
                              </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginate v-if="users && users.data && users.last_page"
                              :page-count="users.last_page"
                              :click-handler="paginate"
                              :prev-text="$trans('Previous')"
                              :next-text="$trans('Next')"
                              :page-class="'page-item'"
                              :prev-class="'page-item'"
                              :next-class="'page-item'"
                              :page-link-class="'page-link'"
                              :prev-link-class="'page-link'"
                              :next-link-class="'page-link'"
                              :container-class="'pagination'">
                    </paginate>
                </slot>

            </div>
        </div>

        <modal v-if="addForm && $can('add-users')" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Add user')}}
                    <button @click="addForm = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="addFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                  <label>{{$trans('Username')}} (*)</label>
                  <input v-model="addFields.username" class="form-control" type="text">
                  <slot v-if="errors && errors['username']">
                    <div class="text-danger" v-for="error in errors['username']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Email')}} (*)</label>
                    <input v-model="addFields.email" class="form-control" type="text">
                    <slot v-if="errors && errors['email']">
                        <div class="text-danger" v-for="error in errors['email']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Password')}} (*)</label>
                    <input v-model="addFields.password" class="form-control" type="text">
                    <slot v-if="errors && errors['password']">
                        <div class="text-danger" v-for="error in errors['password']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
              <div class="form-group">
                <label>{{$trans('Phone')}}</label>
                <input v-model="addFields.phone" class="form-control" type="text">
                <slot v-if="errors && errors['phone']">
                  <div class="text-danger" v-for="error in errors['phone']"
                       v-bind:key="error">
                    <small>{{ error }}</small>
                  </div>
                </slot>
              </div>
                <div class="form-group">
                    <label >{{$trans('Roles')}} (*)</label>
                    <Select2 v-model="addFields.roles" :options="roles" :settings="{  multiple:true}"  />
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input id="add-form-sttaus" type="checkbox" v-model="addFields.status" v-bind:value="1">
                        <label class="form-check-label" for="add-form-sttaus">
                            <span>{{$trans('Active')}}</span>
                        </label>
                    </div>
                </div>
                <button @click="add_user" class="btn btn-primary">
                    <i v-if="process" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Add')}}
                </button>
            </slot>
        </modal>
        <modal v-if="editform && $can('edit-users')" v-bind:width="'500px'" v-bind:height="'100vh'">
            <slot slot="body">
                <h5 class="mb-3">
                    {{$trans('Edit user')}}
                    <button @click="editform = false" class="close">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </h5>
                <small>{{$trans('Fields Marked with (*) are required')}}</small>
                <hr>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Name')}} (*)</label>
                    <input v-model="updateFields.name" class="form-control" type="text">
                    <slot v-if="errors && errors['name']">
                        <div class="text-danger" v-for="error in errors['name']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
              <div class="form-group">
                <label>{{$trans('Username')}} (*)</label>
                <input disabled v-model="updateFields.username" class="form-control" type="text">
                <slot v-if="errors && errors['username']">
                  <div class="text-danger" v-for="error in errors['username']"
                       v-bind:key="error">
                    <small>{{ error }}</small>
                  </div>
                </slot>
              </div>
                <div class="form-group">
                    <label>{{$trans('Email')}} (*)</label>
                    <input disabled v-model="updateFields.email" class="form-control" type="text">
                    <slot v-if="errors && errors['email']">
                        <div class="text-danger" v-for="error in errors['email']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('Password')}} (*)</label>
                    <input v-model="updateFields.password" class="form-control" type="text">
                    <slot v-if="errors && errors['password']">
                        <div class="text-danger" v-for="error in errors['password']"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
              <div class="form-group">
                <label>{{$trans('Phone')}} (*)</label>
                <input v-model="updateFields.phone" class="form-control" type="text">
                <slot v-if="errors && errors['phone']">
                  <div class="text-danger" v-for="error in errors['phone']"
                       v-bind:key="error">
                    <small>{{ error }}</small>
                  </div>
                </slot>
              </div>
                <div class="form-group">
                    <label >{{$trans('Roles')}} (*)</label>
                    <Select2 v-model="updateFields.roles" :options="roles" :settings="{  multiple:true}"  />
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input id="edit-form-sttaus" type="checkbox" v-model="updateFields.status" v-bind:value="1">
                        <label class="form-check-label" for="edit-form-sttaus">
                            <span>{{$trans('Active')}}</span>
                        </label>
                    </div>
                </div>
                <button @click="update_user" class="btn btn-primary">
                    <i v-if="process" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Update')}}
                </button>
            </slot>
        </modal>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Users",
        data:function () {
            return {
                users:null,
                addForm:false,
                editform: false,
                filterQuery:{
                    page:1,
                    keyword:''
                },
                addFields:{
                    name:'',
                    email:'',
                    password:'',
                    roles:[],
                    status:true,
                    username:'',
                    phone:''
                },
                errors:{},
                alert:null,
                response_roles:null,
                process:false,
                updateFields:{}
            }
        },
        methods:{
          filter:function (){
            this.filterQuery.page = 1;
            this.get_users();
          },
            paginate: function (pageNum) {
                this.filterQuery.page = pageNum;
                this.get_users();
            },
            get_users:function () {
                axios({url: this.$root.$data.api_url + '/api/users',params: this.filterQuery, method: 'GET'})
                    .then(resp => {
                        this.users = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            add_user:function(){
                if( this.process == false){
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/users', data:this.addFields,method: 'POST'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if( error_code == 0){
                                this.addForm = false;
                                this.get_users();
                            }else{
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            get_roles:function(){
                axios({url: this.$root.$data.api_url + '/api/allRoles',params: {}, method: 'GET'})
                    .then(resp => {
                        this.response_roles = resp.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            edit:function (user) {
                axios({url: this.$root.$data.api_url + '/api/users/'+user._id, method: 'GET'})
                    .then(resp => {
                        this.updateFields = resp.data.data;
                        this.editform = true;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            update_user:function(){
                if( this.process == false){
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/users/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
                        .then(resp => {
                            let error_code = resp.data.error_code;
                            if( error_code == 0){
                                this.editform = false;
                                this.get_users();
                            }else{
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};
                            }
                            this.process = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.process = false;
                        })
                }
            },
            destroy:function (user) {
                var actionConfirm = confirm("Are you sure?");
                if (actionConfirm === false) {
                    return false;
                }
                axios({url: this.$root.$data.api_url + '/api/users/' + user._id, method: 'DELETE'})
                    .then(() => {
                        this.get_users();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
          set_agency:function (user){
            var actionConfirm = confirm("Are you sure?");
            if (actionConfirm === false) {
              return false;
            }
            let data = {
                user_id:user._id
            }
            axios({url: this.$root.$data.api_url + '/api/agency',data:data, method: 'POST'})
                .then(() => {
                  this.get_users();
                })
                .catch(err => {
                  console.log(err)
                })
          }
        },
        created() {
            this.get_users();
            this.get_roles();
        },
        computed:{
            roles:function () {
                if( !this.response_roles){
                    return [];
                }
                let roles =[];
                const  response_roles = this.response_roles;
                response_roles.forEach((el)=>{
                    let item ={
                        id:el.slug,
                        text:el.name
                    }
                    roles.push(item)
                })

                return  roles;
            }
        }
    }
</script>

<style scoped>

</style>